import cn from 'classnames';
import Image from 'gatsby-image';
import React from 'react';
import styles from './image-text-section.module.scss';
import BackgroundImage from 'gatsby-background-image';

function FeatureList({ features }) {
  return <ul className={styles.features}>
    {features.map((feature, i) => <li key={i}>{feature}</li>)}
  </ul>
}

export default function ImageTextSection(props) {
  const {
    backgroundColor,
    backgroundImage,
    image,
    imageStyle,
    title,
    desc,
    features,
    textAlign,
    textBackground,
  } = props;

  const inlineStyle = {};

  if (backgroundColor) {
    inlineStyle.backgroundColor = backgroundColor;
  }

  const Section = backgroundImage ? BackgroundImage : 'section';
  const cls = cn(styles.content, styles[textAlign]);

  return <Section
    style={inlineStyle}
    fixed={backgroundImage}
  >
    <div className={cls}>
      <div className={styles.intro} style={{ background: textBackground}}>
        <div className={styles.introContent}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.desc}>{desc}</p>
          <FeatureList features={features} />
        </div>
      </div>
      {image ? <div className={styles.image}><Image fixed={image} style={imageStyle} /></div> : null}
    </div>
  </Section>
}

ImageTextSection.defaultProps = {
  backgroundColor: '#fff',
  backgroundImage: '',
  textAlign: 'left',
  textBackground: 'none',
}
