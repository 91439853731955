import React from 'react';
import GenericSection from './generic-section';
import SocialMatrix from './social-matrix';

export default function ChannelSection() {
  return <>
    <GenericSection
      title="【知了健康测试】，值得关注！"
      desc={<>「朋克养生」党，年轻白领一族，失眠派，虚胖哥……扫码关注公众号【知了健康测试】，获取专业的、系统的科普知识，掌握最新的专业健康测评。这里是年轻人的健康乐园，是「慢<br />病族」的掌上助手，是在外工作的你给爸爸妈妈最好的礼物……</>}
      style={{ background: '#f0f0f0' }}
    >
      <SocialMatrix />
    </GenericSection>
  </>
}
