import { graphql } from 'gatsby';
import React from 'react';
import ChannelSection from '../components/channel-section';
import ImageTextSection from '../components/image-text-section';
import PageLayout from '../components/page-layout';
import SimpleBanner from '../components/simple-banner';
import populateGatsbyImage from '../utils/populateGatsbyImage';
import SEO from '../components/seo';

const imageTextSectionsData = [
  {
    title: '心理健康测评（专业版）',
    desc: '据调查，我国抑郁症患者有7000万+人，其中2/3的人曾有过自杀的想法。那么，抑郁症有哪些表现？怎样判断是抑郁情绪还是抑郁症？',
    features: [
      '参照SCL-90量表，其为国际医疗机构和国内三甲医院通用的诊断参考标准之一。',
      '科学性&准确性，业内广泛认可。',
      '详细的测评报告及改善建议，更符合中国人的需要。',
    ],
    image: 'image1',
    textAlign: 'right'
  }, {
    title: '睡眠质量测试（专业版）',
    desc: '关于睡眠，真的是越年轻越苦涩。据调查显示，37.1%的80后和35.7%的90后都存在或多或少的睡眠问题。那么，你的睡眠还好吗？是否存在睡眠问题？睡眠无小事，千万不可掉以轻心！在线睡眠质量评估……',
    features: [
      '参考国际公认权威AIS、ESS量表。',
      '结合多方面科研文献，综合研发。',
      '详细的睡眠质量测评报告：冒死折颜派？万年瞌睡派？',
    ],
    image: 'image2',
    backgroundColor: '#F0F0F0',
  }, {
    title: '九型体质测试（专业版）',
    desc: '为什么别人减肥轻轻松松，可是你却喝水都会胖？为什么同样熬夜，别人在享受青春，你却在享受战「痘」？其实，不同体质的人需要注意的事情也不相同，了解自己是什么类型的体质，积极调理和改善，才能拥有更健康的人生。',
    features: [
      '参照《中医体质分类与判定》，为我国第一部指导和规范中医体质研究及应用的文件。',
      '通过21948例流行病学调查，该标准具有指导性、普遍性及可参照性。',
      '详细的测评报告分析及改善建议等，更具指导意义。',
    ],
    image: 'image2',
    textAlign: 'right',
  }
];

export default function HealthAssessmentPage({ data }) {
  const { banner } = data;

  return <PageLayout pageKey="/health-assessment">
    <SEO title="健康测评" />

    <SimpleBanner
      image={banner.childImageSharp.fixed}
      title="健康测评"
      desc={<p>为什么你总是战「痘」英雄？情绪低落就是抑郁症吗？最近总是失眠怎么办？不同体质适合怎么减肥？<br />平常慢病管理怎样改善？月经健康程度测试……</p>}
      contentStyle={{ backgroundColor: 'rgba(38, 38, 38, 0.43)' }}
    />
    {imageTextSectionsData.map((item, i) => <ImageTextSection
      key={i}
      imageStyle={{ borderRadius: 10000, boxShadow: '0px 7px 6px 0px rgba(0, 0, 0, 0.4)' }}
      {...populateGatsbyImage(data, item, 'image')}
    />)}
    <ChannelSection />
  </PageLayout>
}

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "banner-assessment.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 816) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    image1: file(relativePath: {eq: "img-assessment-1.png"}) {
      childImageSharp {
        fixed(width: 408, height: 408) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    image2: file(relativePath: {eq: "img-assessment-1.png"}) {
      childImageSharp {
        fixed(width: 408, height: 408) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
  }
`;
